// Generated by Framer (b35efa8)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["AYUP8dlR_"];

const variantClassNames = {AYUP8dlR_: "framer-v-1kscyl"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; title?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "AYUP8dlR_", title: zvTpOKqto = "Customer Traffic", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "AYUP8dlR_", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-hePWF", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? undefined}}>
<motion.div {...restProps} className={cx("framer-1kscyl", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"AYUP8dlR_"} ref={ref} style={{...style}} transition={transition}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO0FsdG9uZSBTZW1pIEJvbGQ=", "--framer-font-family": "\"Altone Semi Bold\", serif", "--framer-font-size": "29px", "--framer-line-height": "32.46px", "--framer-text-alignment": "left", "--framer-text-color": "var(--extracted-r6o4lv)"}}>Customer Traffic</motion.p></React.Fragment>} className={"framer-bozy3"} data-framer-name={"Customer Traffic"} fonts={["CUSTOM;Altone Semi Bold"]} layoutDependency={layoutDependency} layoutId={"NvWfAJGvY"} style={{"--extracted-r6o4lv": "var(--token-512a643a-6a48-47c6-8641-27985ce400fb, rgb(32, 23, 71)) ", "--framer-paragraph-spacing": "0px"}} text={zvTpOKqto} transition={transition} verticalAlignment={"center"} withExternalLayout/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-hePWF [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-hePWF * { box-sizing: border-box; }", ".framer-hePWF .framer-1x34b63 { display: block; }", ".framer-hePWF .framer-1kscyl { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: 237px; }", ".framer-hePWF .framer-bozy3 { flex: none; height: auto; overflow: hidden; position: relative; white-space: pre-wrap; width: 237px; word-break: break-word; word-wrap: break-word; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-hePWF .framer-1kscyl { gap: 0px; } .framer-hePWF .framer-1kscyl > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-hePWF .framer-1kscyl > :first-child { margin-left: 0px; } .framer-hePWF .framer-1kscyl > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 65
 * @framerIntrinsicWidth 237
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerVariables {"zvTpOKqto":"title"}
 */
const FramerkWWu2xS3S: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerkWWu2xS3S;

FramerkWWu2xS3S.displayName = "Archive / title";

FramerkWWu2xS3S.defaultProps = {height: 65, width: 237};

addPropertyControls(FramerkWWu2xS3S, {zvTpOKqto: {defaultValue: "Customer Traffic", displayTextArea: true, title: "Title", type: ControlType.String}} as any)

addFonts(FramerkWWu2xS3S, [{family: "Altone Semi Bold", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/kWWu2xS3S:default", url: "assets/eLRPJ4AGPDPAzDyRKaAS9s7LY8.ttf"}, url: new URL("assets/eLRPJ4AGPDPAzDyRKaAS9s7LY8.ttf", import.meta.url).href}])